//eslint-disable-next-line
@import "carbon-components/scss/globals/scss/styles.scss";

html,
body,
#root {
  min-height: 100%;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#root {
  min-height: inherit;
}

a {
  text-decoration: none;
}

.active-link {
  background-color: #598BFE;
}

.upload-styled {
  justify-content: center;
  background-color: white;
  align-items: center;
}

.upload-error {
  border-color: red;
  color: red;
}

.appbar-img {
  height: 54px;
}

.oxt-button {
  border-radius: 8px;
  font-size: 16px;
  min-width: 200px;
}

.button-icon {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.oxt-notify {
  max-width: 300px;
  font-size: 14px;
}

.tab-title {
  font-size: 1rem;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
}

.tab-subtitle {
  font-size: 0.8rem;
  color: #4B4B4B;
  text-transform: capitalize;
}

.subtext {
  color: #4B4B4B;
  font-weight: 400;
}

.text-link {
  color: #1454F6;
  cursor: pointer;
  font-weight: 400;
}

.carbon-label-input {
  letter-spacing: 0.32px;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: inherit;
  font-weight: 400;
  line-height: 1rem;
  vertical-align: baseline;
}

.carbon-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  color: inherit;
  background-color: #f4f4f4;
  border: none;
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.carbon-invalid-input {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
}

.invalid-label {
  color: #da1e28;
  display: block;
  max-height: 12.5rem;
  font-size: 0.75rem;
  overflow: visible;
  font-weight: 400;
}

.vdr-tab-header {
  margin-right: 16px;
}

.asset-details-img {
  max-width: 270px;
  max-height: 70px;
  height: auto;
}

.input-available {
  color: #161616
}

.input-disabled {
  color: #c6c6c6;
  cursor: not-allowed
}

:root {
  --primary: #28406A;
  --secondary: #598BFE;
  --tertiary: #62C6E9;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body {
  background-color: #FFFFFF;
  min-height: 100vh;
}

img {
  max-width: 100%;
  display: block;
}

.box-shadow {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25) !important;
}

.bx--label {
  font-size: 14px;
}

.bx--text-input {
  font-family: inherit !important;
}

.bx--text-input:read-only {
  cursor: not-allowed;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin: 0;
}

.Mui-selected {
  color: var(--secondary)!important ;
}

.MuiTabs-indicator {
  background-color: var(--secondary) !important;
}

.card {
  position: absolute;
  min-height: 430px;
}

.card-container {
  position: relative;
}

.img-card {
  object-fit: cover;
  border-radius: 7px 7px 0 0;
  height: 290px;
}

.header-card {
  position: absolute;
  right: 0;
}

@media (min-width: 1024px) {
  h1 { font-size: 4rem; }
  h2 { font-size: 3.5rem; }
  h3 { font-size: 3rem; }
  h4 { font-size: 2.5rem; }
  h5 { font-size: 2rem; }
  h6 { font-size: 1.5rem; }
  p { font-size: 1.125rem; }
}
@media (max-width: 1023px) {
  h1 { font-size: 3.875rem; }
  h2 { font-size: 3.375rem; }
  h3 { font-size: 2.875rem; }
  h4 { font-size: 2.375rem; }
  h5 { font-size: 1.875rem; }
  h6 { font-size: 1.375rem; }
  p { font-size: 1rem; }
}
@media (max-width: 768px) {
  h1 { font-size: 3.625rem; }
  h2 { font-size: 3.125rem; }
  h3 { font-size: 2.625rem; }
  h4 { font-size: 2.125rem; }
  h5 { font-size: 1.625rem; }
  h6 { font-size: 1.125rem; }
  p { font-size: 0.875rem; }
}
@media (max-width: 480px) {
  h1 { font-size: 3.625rem; }
  h2 { font-size: 3.125rem; }
  h3 { font-size: 2.625rem; }
  h4 { font-size: 2.125rem; }
  h5 { font-size: 1.625rem; }
  h6 { font-size: 1.125rem; }
  p { font-size: 0.875rem; }
}

h1 {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  overflow-wrap: break-word;
  line-height: 6rem;
}

h2 {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  overflow-wrap: break-word;
  line-height: 5.25rem;
}

h3 {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  overflow-wrap: break-word;
  line-height: 4.5rem;
}

h4 {
  font-weight: 500;
  color: #333333;
  line-height: 3.75rem;
}

h5 {
  font-weight: 500;
  color: #333333;
  line-height: 3rem;
}

h6 {
  font-weight: 500;
  color: #333333;
  line-height: 2.25rem;
}

p {
  color: #3D4D6A;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
}

strong {
  font-weight: 600;
}

.card div {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  transition: all .7s ease;
}

.card .front {
  transform: perspective(700px) rotateY(0deg);
}

.card .back {
  transform: perspective(700px) rotateY(180deg);
}

.card:hover .front {
  transform: perspective(700px) rotateY(180deg);
}

.card:hover .back {
  transform: perspective(700px) rotateY(360deg);
}

.content-card-back {
  background-color: rgba(0, 0, 0, 0.31);
}

.content-card-back h4, img {
  color: white;
}

.bx--tab-content {
  padding: 0;
  margin-top: 2rem;
}

.bx--tabs--scrollable__nav {
  z-index: 4;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link, .bx--tabs--scrollable .bx--tabs--scrollable__nav-item--selected .bx--tabs--scrollable__nav-link:focus {
  border-bottom: 2px solid var(--primary);
}

.vdr-tab-individual > button[type="button"][role="tab"][aria-selected="true"] {
  border-bottom: 2px solid white;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-item:hover .bx--tabs--scrollable__nav-link {
  border-bottom: 2px solid #E0E0E0;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-link {
  border-bottom: none;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:focus,
.bx--tabs--scrollable .bx--tabs--scrollable__nav-link:active {
  outline: none;
}

.bx--tabs--scrollable .bx--tabs--scrollable__nav-item .bx--tabs--scrollable__nav-link {
  width: auto;
  transition: none;
}

.text-link {
  color: var(--primary);
}

.vdr-tab-header, .vdr-tab {
  background-color: #E6EAF2;
  color: red;
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.38);
  left: 12px;
  padding-left: 1px;
}

.react-tel-input .form-control {
  padding: 16.5px 14px 18.5px 58px;
  width: 100%;
}

.react-tel-input .country-list .search-box {
  margin-left: 0;
  width: 100%;
}

.react-tel-input .country-list .search {
  padding: 10px 6px 6px 10px;
}

.language-picker {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
  select {
    font-size: 16px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    appearance: none;
    background-color: white;
  }
}
